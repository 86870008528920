.accordion-no-padding {
  & .p-accordion-content {
    padding: 0;
  }
}

.accordion-no-highlight {
  & .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
  }
}


.accordion-menu-style {
}