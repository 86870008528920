$dropdown-link-color: #0553c4;
$active-link-color: #2196f3;

.dropdown-no-border {
  &.p-dropdown {
    border: 0;
    background-color: transparent;

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }
  }
}

.dropdown-label-as-active-link {
  &.p-dropdown {
    .p-dropdown-label {
      color: $dropdown-link-color;

      &:hover {
        text-decoration: underline;
        color: $active-link-color;
      }
    }
  }
}

.dropdown-no-caret {
  &.p-dropdown {
    .p-dropdown-trigger {
      display: none;
    }
  }
}
