// cd - custom dialog
.cd-no-padding {
  & .p-dialog-content {
    padding: 0;
  }
}

.cd-with-radius-5 {
  border-radius: 5px;
}

.cd-97-vw {
  &.p-dialog {
    width: 97vw;
    max-width: 97vw;
  }
}

.cd-95-vh {
  &.p-dialog {
    height: 95vh;
    max-height: 95vh;
  }
}

@for $i from 5 through 9 {
  // the next part generate classes .cd-50-vw, ..., cd-90-vw
  .cd-#{$i*10}-vw {
    &.p-dialog {
      width: #{$i*10}vw;
      max-width: #{$i*10}vw;
    }
  }
  // the next part generate classes .cd-50-vh, ..., cd-90-vh
  .cd-#{$i*10}-vh {
    &.p-dialog {
      height: #{$i*10}vh;
      max-height: #{$i*10}vh;
    }
  }
}
