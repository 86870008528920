.tri-state-input-switch {
  .p-inputswitch-slider {
    &::before {
      content: '+';
      transform: rotate(45deg);
      line-height: 1.25rem;
      font-size: 1.625rem;
      text-align: center;
    }
  }
}
