.paginator-transparent {
  &.p-paginator {
    background-color: transparent;
  }
}

.paginator-nowrap {
  &.p-paginator {
    flex-wrap: nowrap;
  }
}

.paginator-with-narrow-dropdown {
  &.p-paginator {
    flex-wrap: nowrap;

    p-dropdown {
      display: flex;
      width: auto;
    }

    .p-dropdown {
      margin: 0;
    }
  }
}
