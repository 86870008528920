// Google fonts
@import url('https://fonts.googleapis.com/css2?family=Akronim&family=Ephesis&family=Gemunu+Libre&family=Noto+Sans+Display&family=Nunito&family=Open+Sans+Condensed:wght@300&family=Pacifico&family=Roboto&family=Source+Code+Pro&family=Stick+No+Bills&display=swap');

$gutter: 1rem; //for prime flex grid system

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import 'assets/flags/flags.css';
@import 'assets/sass/dynamic-label';
@import 'assets/layout/css/bootstrap-grid.min.css';
@import 'assets/sass/artifact-widget';
@import 'assets/sass/accordion-custom';
@import 'assets/sass/overlay-panel-custom';
@import 'assets/sass/paginator-custom';
@import 'assets/sass/datatable-custom';
@import 'assets/sass/input-switch-custom';
@import 'assets/sass/tree-select-custom';
@import 'assets/sass/tinymce-custom';
@import 'assets/sass/dialog-custom';
@import 'assets/sass/dropdown-custom';
@import 'assets/sass/multiselect-custom';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@Keyframes p-component-overlay-leave-animation {
  from {
    background-color: rgba(0, 0, 0, 0.4);
  }
  to {
    background-color: transparent;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(#ccc, 0.4);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(#000, 0.4);
  border-radius: 3px;

  &:hover {
    background-color: rgba(#000, 0.6);
    cursor: pointer;
  }
}

.w-100 {
  width: 100%;
}

.e-pc .pc-hide {
  display: none !important;
}

.e-tab .tab-hide {
  display: none !important;
}

.e-mob .mob-hide {
  display: none !important;
}

.mandatory-field {
  display: block;
  width: 100%;
  position: relative;

  &::after {
    display: block;
    content: '*';
    font-size: 20px;
    position: absolute;
    top: -15px;
    right: 3px;
    color: red;
  }
}

// Table styles
.table-cell:hover .button-add-link {
  visibility: visible;
}

.table_buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -2px 0;

  > .p-button,
  > a {
    margin: 2px 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.nowrap {
    flex-wrap: nowrap;
  }
}

.table-mandatory-field {
  display: block;
  width: 100%;

  &::after {
    display: block;
    content: '*';
    position: absolute;
    top: -10px;
    right: 0;
    color: red;
  }
}

.table-string-multiple-item,
.table-hyperlink-item {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #75c0f4;
  color: #ffffff;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
}

.table-hyperlink-item {
  padding: 0;
  transition: background-color 0.2s;
  border: 1px solid white;
}

.table-hyperlink-item:hover {
  background-color: #2196f3;
}

.table-hyperlink-item a {
  transition: color 0.2s;
  padding: 0.25rem 0.5rem;
  color: white;
  display: block;
}

.table-hyperlink-item[isSingle] {
  background-color: white;
}

.table-hyperlink-item[isSingle]:hover {
  background-color: #2196f3;
}

.table-hyperlink-item[isSingle]:not(:hover) a {
  color: #2196f3;
}

.table-icon-label {
  display: block;
  width: fit-content;
  max-width: 97%;
  padding: 2px 7px;
  margin: 2px;
  border-radius: 16px;
  position: relative;

  & > .icon {
    font-size: 17px;
    position: absolute;
    top: 3px;
    left: 8px;

    &-place {
      display: inline-block;
      width: 16px;
      margin-right: 6px;
    }
  }
}

.p-text-truncate {
  .table-icon-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.p-column-filter-overlay {
  background-color: inherit;
  box-shadow: 0 0 10px;
  border-radius: 0.25rem;
  padding: 0.3rem;
}

.p-column-filter-buttonbar {
  padding-top: 0.5rem;

  & > button {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.p-column-filter-menu-button-active {
  color: #2196f3;
}

.sort-icon {
  .pi-sort-amount-up-alt,
  .pi-sort-amount-down {
    color: #2196f3 !important;
  }
}

.p-text-wrap {
  word-break: break-word;
}

.p-text-wrap-new-lines {
  white-space: pre-line !important;
}

.sort-parent {
  .p-column-filter-menu-button,
  .pi-sort-alt {
    transition: visibility 0.1s;
    visibility: hidden;
  }

  &:hover {
    background: #e9ecef;
    color: #495057 !important;

    .p-column-filter-menu-button,
    .pi-sort-alt {
      visibility: visible;
    }
  }

  .p-column-filter-menu-button-active {
    visibility: visible;
  }
}

.p-datatable-resizable .p-datatable-tbody > tr > td,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable > .p-datatable-wrapper {
  overflow: inherit !important;
  white-space: inherit !important;
}

.sticky .p-datatable-scrollable .p-datatable-scrollable-wrapper .p-datatable-scrollable-view .p-datatable-scrollable-header {
  position: sticky;
  top: 0;
  z-index: 250;
}

.p-datatable-scrollable-body {
  overflow: hidden !important;
}

.p-datatable-scrollable-body:hover {
  overflow: auto !important;
}

.p-datatable-header,
.p-datatable-footer {
  padding: 0 !important;
  border: none !important;
}

.p-column-title {
  display: none;
}

.p-paginator-bottom {
  display: flex;
  flex-wrap: nowrap;

  > p-dropdown {
    width: 80px;
  }
}

.row-deleted {
  border-left: 2px outset red;
}

.deleted-row-color {
  color: red !important;
}

.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-datatable-reorderablerow-handle,
[pReorderableColumn] {
  cursor: pointer !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: transparent !important;
  color: #2196f3;
}

@media screen and (max-width: 40rem) {
  .p-datatable-responsive {
    table-layout: unset !important;

    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;

      .p-column-title {
        padding: 0.4rem;
        min-width: max-content;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
      }

      .p-text-truncate {
        overflow: inherit;
      }

      &:last-child {
        border-bottom: 1px solid var(--surface-d);
      }
    }
  }
}

.tox-tinymce {
  z-index: 45 !important;
}

.sidebar-tox-tinymce {
  z-index: 1102 !important;
}

// Components width
app-row-item,
app-system-user-form,
app-admin-user-form {
  width: 100%;
}

p-dropdown,
.p-dropdown {
  width: 100%;
}

// Skeleton
.p-skeleton {
  background-color: #dee2e6;
  border-radius: 6px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

// User details page
.groups-column {
  display: flex;
  flex-direction: column;

  p-tree {
    flex-grow: 1;

    .p-tree {
      height: 100% !important;
    }
  }
}

// Enumerated details component
.enum-list_item {
  & > .p-float-label {
    margin-right: 10px;

    &:last-child {
      flex-grow: 1;
    }

    & > input {
      width: 100%;
    }

    .p-dropdown-panel {
      width: max-content;
    }
  }

  & > .order-field {
    width: 70px;

    & > input {
      max-width: inherit;
    }
  }

  & > .icon-field {
    min-width: 100px;

    p-dropdown,
    .p-dropdown {
      width: 100%;
    }
  }

  & > .color-field {
    display: flex;
    align-items: center;

    & > input {
      margin-right: 3px;
    }
  }

  & > .uri-field {
    flex-grow: 1;
    min-width: 150px;
  }
}

@media (max-width: 450px) {
  .enum-list_item {
    flex-wrap: wrap;

    & > .p-float-label {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    & > .p-button {
      width: 100%;
    }
  }
}

// Page Builder
.list_top {
  &__filters {
    width: calc(100% - 6px);
    padding: 15px 0 35px 0;

    .p-component {
      width: 100% !important;
    }
  }
}

.p-treenode-label {
  width: 100%;
}

.page-builder p-sidebar .p-sidebar {
  overflow-y: auto;
}

.p-sidebar {
  &-mask {
    background-color: rgba(#000, 0) !important;
  }

  &.widget-advanced-mode-sidebar {
    width: 40em;

    @media screen and (max-width: 992px) {
      z-index: 999 !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &.p-sidebar-full {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .p-sidebar {
    z-index: 999 !important;
  }
}

@media screen and (max-width: 767px) {
  .p-sidebar {
    width: 100% !important;
  }
}

.page-settings-button {
  margin-right: 5px;

  & > span {
    font-size: 20px;
  }
}

// Error message
p-message {
  .p-inline-message-error {
    justify-content: flex-start;
    width: 100%;
  }
}

.visibility-hidden {
  visibility: hidden;
}

// Common components styles
.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #6c757d;
}

.p-multiselect .p-multiselect-panel,
.p-dropdown .p-dropdown-panel {
  width: 100%;
  min-width: 200px !important;
}

.p-tooltip {
  max-width: 20rem;

  div:nth-child(2) {
    word-break: break-word;
  }
}

ul {
  li {
    &.p-text-truncate {
      list-style-position: inside;
      padding-left: 0;

      a {
        position: relative;
        left: -10px;
      }
    }

    &.p-text-wrap {
      list-style-position: outside;
      padding-left: 0;
      margin-left: 14px;

      a {
        position: relative;
        left: -5px;
      }
    }
  }
}

.menu-widget_menu .p-menubar .p-submenu-list {
  z-index: 1001;
}

.display-none {
  display: none;
}

.full-width-container {
  width: 100%;
}

.full-height-container {
  height: 100%;
}

.full-size-container {
  width: 100%;
  height: 100%;
}

.container-with-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.block-container {
  display: block;
}

.group-paginator {
  .p-paginator {
    justify-content: flex-start;
  }
}

.cursor-pointer,
.clickable {
  cursor: pointer;
}

.settings-checkbox-label {
  margin-left: 7px;
}

.failed-widget {
  animation: failed-widget-animation 8s;
}

.p-divider-solid.p-divider-horizontal:before {
  border-width: thin;
}

.deleted-directive-page-notification {
  color: red;
  font-size: 10px;
}

@keyframes failed-widget-animation {
  65% {
    box-shadow: -2px 0px 13px 7px #f27989;
  }
  100% {
    box-shadow: none;
  }
}

.text-red {
  color: red;
}

.text-bold {
  font-weight: bold;
}

.responsive {
  .sub-menu {
    position: static !important;
  }
}

.directional-link-type {
  position: relative;
  max-width: 90%;

  &.dropdown-value {
    display: flex;
    align-items: center;

    &::after {
      @extend .ml-1;

      position: static;
      transform: none;
    }
  }

  &::after,
  &::before {
    font-family: 'primeicons';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.3rem;
    line-height: 1;
    position: absolute;
    right: -15%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--OUTGOING {
    &::after {
      content: '\e920';
      color: green;
    }
  }

  &--INCOMING {
    &::after {
      content: '\e91f';
      color: darkgoldenrod;
    }
  }
}

.p-autocomplete {
  width: 100%;

  & > ul, input {
    width: 100%;

    & > li {
      margin-bottom: 3px;
    }
  }
}

.place-items-right {
  place-items: end;
  justify-content: end;
}

.place-items-center {
  place-items: center;
  justify-content: center;
}

.self-center {
  align-self: center;
}

.pseudo-opacity {
  position: relative;
  --background-color: transparent;
  --background-repeat: no-repeat;
  --background-size: cover;
  --background-position: center bottom;
  --background-attachment: scroll;
  --background-image: "";
  --opacity: 1;
  --border-top-width: 0;
  --border-right-width: 0;
  --border-bottom-width: 0;
  --border-left-width: 0;
  --border-top-style: none;
  --border-right-style: none;
  --border-bottom-style: none;
  --border-left-style: none;
  --border-top-color: none;
  --border-right-color: none;
  --border-bottom-color: none;
  --border-left-color: none;
  --border-top-left-radius: 0;
  --border-top-right-radius: 0;
  --border-bottom-right-radius: 0;
  --border-bottom-left-radius: 0;
  --border-radius-range: none;
  --box-shadow: none;
}

.pseudo-opacity::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  background-repeat: var(--background-repeat);
  background-size: var(--background-size);
  background-position: var(--background-position);
  background-attachment: var(--background-attachment);
  background-image: var(--background-image);
  opacity: var(--opacity);
  border-top-width: var(--border-top-width);
  border-right-width: var(--border-right-width);
  border-bottom-width: var(--border-bottom-width);
  border-left-width: var(--border-left-width);
  border-top-style: var(--border-top-style);
  border-right-style: var(--border-right-style);
  border-bottom-style: var(--border-bottom-style);
  border-left-style: var(--border-left-style);
  border-top-color: var(--border-top-color);
  border-right-color: var(--border-right-color);
  border-bottom-color: var(--border-bottom-color);
  border-left-color: var(--border-left-color);
  border-top-left-radius: var(--border-top-left-radius);
  border-top-right-radius: var(--border-top-right-radius);
  border-bottom-right-radius: var(--border-bottom-right-radius);
  border-bottom-left-radius: var(--border-bottom-left-radius);
  border-radius-range: var(--border-radius-range);
  box-shadow: var(--box-shadow);
  pointer-events: none;
  z-index: -1;
}

.box-shadow-generator,
.p-accordion-shared-styles-settings {
  & .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.5rem;
  }
}
