// cop - custom overlay panel
.cop-no-padding {
  & .p-overlaypanel-content {
    padding: 0;
  }
}

.cop-no-caret {
  &.p-overlaypanel {
    &::before, &::after {
      display: none;
    }
  }
}

.cop-as-flex {
  &.p-overlaypanel {
    display: flex;
    flex-direction: column;
  }
}

.cop-with-content-overflow {
  & .p-overlaypanel-content {
    overflow: auto;
  }
}
