.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: #3B82F6;
}

.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
  border-color: #3B82F6;
}

.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #e24c4c;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-treeselect-panel .p-treeselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.75rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #6c757d;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1.25rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #e9ecef;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #e9ecef;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.75rem;
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #6c757d;
  right: 3rem;
}

.p-treeselect-close.p-link .p-icon-wrapper {
  justify-content: center;
}

.p-treenode-content {
  padding: 0 !important;
}

.group-node {
  .p-tree-toggler {
    color: var(--primary-color) !important;
  }
}
