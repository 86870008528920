$multiselect-link-color:      #0553c4;
$active-link-color:           #2196f3;

.multiselect-no-border {
  &.p-multiselect {
    border: 0;

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }
  }
}

.multiselect-label-as-active-link {
  &.p-multiselect {
    .p-multiselect-label {
      color: $multiselect-link-color;

      &:hover {
        text-decoration: underline;
        color: $active-link-color;
      }
    }
  }
}

.multiselect-no-caret {
  &.p-multiselect {
    .p-multiselect-trigger {
      display: none;
    }
  }
}
