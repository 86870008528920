.dynamic-label {
  display: block;
  width: 100%;
  position: relative;

  .p-component:not(.p-inputswitch),
  .p-chips-multiple-container,
  .p-calendar {
    width: 100%;

    .p-datepicker-trigger {
      width: 2.357rem;
    }
  }

  .p-checkbox {
    width: 20px !important;
  }

  .p-datepicker {
    width: auto;
    min-width: 320px !important;
  }

  .editor-enabled {
    .mce-content-body {
      outline-width: 0;
      padding: 7px;
      border: 1px solid var(--surface-d);
      //background-color: var(--surface-b);
      border-radius: 3px;

      &:hover {
        border-color: var(--primary-color);
      }

      &:focus {
        outline-width: 0;
        border: 1px solid var(--surface-d);
        //background-color: var(--surface-b);
        border-radius: 3px;
      }
    }
  }
}

.layout-sidebar-darkgray {
  .dynamic-label {
    .editor-enabled {
      .mce-content-body {
        background-color: #fff;
      }
    }
  }
}

.dynamic-label-text {
  max-width: 90%;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all .2s;
  pointer-events: none;
}

.dynamic-label:not(:last-child) {
  margin-bottom: 20px;
}

.p-inputgroup {
  .dynamic-label {
    margin-bottom: 0;
  }
}

.p-inputgroup:not(:last-child) {
  margin-bottom: 20px;
}

.dynamic-float-label {
  .dynamic-label {
    .p-filled,
    .p-inputwrapper-filled,
    .p-inputwrapper-focus,
    .p-chips-filled,
    .p-component:focus {
      ~ .dynamic-label-text {
        top: -18px;
        font-size: 12px;
      }
    }
  }
}

.dynamic-static-label {
  .dynamic-label {
    .dynamic-label-text {
      top: -18px;
      font-size: 12px;
    }

    .p-filled,
    .p-inputwrapper-filled,
    .p-inputwrapper-focus,
    .p-chips-filled,
    .p-component:focus {
      ~ .dynamic-label-text {
        top: -18px;
        font-size: 12px;
      }
    }
  }
}

.dynamic-placeholder-label {
  .dynamic-label {
    .dynamic-label-text {
      color: #5a5959;
    }

    .p-filled,
    .p-inputwrapper-filled,
    .p-chips-filled {
      ~ .dynamic-label-text {
        display: none;
      }
    }

    .p-inputwrapper-focus,
    .p-component:focus {
      ~ .dynamic-label-text {
        top: 8px;
        color: #5a5959;
      }
    }
  }
}

.dynamic-inline-label {
  .dynamic-label {
    padding-left: 20%;

    .dynamic-label-text {
      width: 100%;
      max-width: 20%;
      padding-right: 5px;
      left: 0;
    }

    .p-filled,
    .p-inputwrapper-filled,
    .p-inputwrapper-focus,
    .p-chips-filled,
    .p-component:focus {
      ~ .dynamic-label-text {
        top: 8px;
      }
    }
  }

  .p-inputgroup {
    position: relative;
    padding-left: 20%;

    .dynamic-label {
      position: static;
      padding-left: 0;
    }
  }
}

.dynamic-inline-label-right {
  .dynamic-label {
    .dynamic-label-text {
      text-align: right;
    }
  }
}

.dynamic-inline-label-left {
  .dynamic-label {
    .dynamic-label-text {
      text-align: left;
    }
  }
}

.dynamic-radiobutton,
.dynamic-checkbox {
  display: flex;
  flex-wrap: wrap;

  .dynamic-label-text {
    color: inherit;
    top: -18px;
    font-size: 12px;
  }
}

.dynamic-inline-label {
  .dynamic-radiobutton,
  .dynamic-checkbox {
    .dynamic-label-text {
      color: inherit;
      top: 8px;
      font-size: 14px;
    }
  }
}

.dynamic-radiobutton-box,
.dynamic-checkbox-box {
  display: flex;
  margin-right: 15px;
  margin-top: 8px;

  & > label {
    margin-left: 3px;
    cursor: pointer;
  }
}
