/*
  Artifact widget styles
*/
.artifact-widget-label {
  .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
    cursor: text;
  }

  .p-placeholder,
  ::placeholder {
    color: var(--placeholder-color) !important;
  }

  .p-slider {
    margin-bottom: 10px;
  }

  .form-mandatory-field {
    &::after {
      position: absolute;
      left: -10px;
      top: -3px;
      font-size: 12px;
      color: red;
      content: '*';
    }
  }

  .dynamic-label, .readonly-attribute-wrapper {
    display: block;
    width: 100%;
    position: relative;
    word-break: break-word;
    padding-left: 0;

    .dynamic-label-text {
      font-size: 14px;
    }

    .p-component:not(.p-inputswitch),
    .p-chips-multiple-container,
    .p-calendar {
      width: 100%;

      .p-datepicker-trigger {
        width: 2.357rem;
      }
    }

    .p-checkbox {
      width: 20px !important;
    }

    .p-datepicker {
      width: auto;
      min-width: 320px !important;
    }

    .editor-enabled {
      .mce-content-body {
        outline-width: 0;
        padding: 7px;
        border: 1px solid var(--surface-d);
        //background-color: var(--surface-b);
        border-radius: 3px;

        &:hover {
          border-color: var(--primary-color);
        }

        &:focus {
          outline-width: 0;
          border: 1px solid var(--surface-d);
          //background-color: var(--surface-b);
          border-radius: 3px;
        }
      }
    }
  }

  .dynamic-label:not(:last-child) {
    margin: 0;
  }

  .p-inputgroup {
    margin: 0;

    .dynamic-label {
      margin: 0;
    }
  }

  // .dynamic-checkbox-box {
  //   .dynamic-label-text {
  //     top: 0 !important;
  //     left: 30px !important;
  //     text-align: left !important;
  //   }
  // }

  .dynamic-float-label {
    .dynamic-label {
      .p-filled,
      .p-inputwrapper-filled,
      .p-inputwrapper-focus,
      .p-chips-filled,
      .p-component:focus {
        ~ .dynamic-label-text {
          top: inherit;
          font-size: inherit;
        }
      }
    }
  }

  .dynamic-static-label {
    .dynamic-label {
      .dynamic-label-text {
        top: inherit;
        font-size: inherit;
      }

      .p-filled,
      .p-inputwrapper-filled,
      .p-inputwrapper-focus,
      .p-chips-filled,
      .p-component:focus {
        ~ .dynamic-label-text {
          top: inherit;
          font-size: inherit;
        }
      }
    }
  }

  .dynamic-placeholder-label {
    .dynamic-label {
      .dynamic-label-text {
        color: inherit;
      }

      .p-filled,
      .p-inputwrapper-filled,
      .p-chips-filled {
        ~ .dynamic-label-text {
          display: none;
        }
      }

      .p-inputwrapper-focus,
      .p-component:focus {
        ~ .dynamic-label-text {
          top: inherit;
          color: inherit;
        }
      }
    }
  }

  .dynamic-label-text {
    position: absolute;
    top: 8px;
    left: 0;
    height: 20px;
    max-width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s;
    pointer-events: none;
  }

  .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
    position: absolute;
    color: var(--placeholder-color);
  }

  --horizontal-align: -webkit-left;
  --vertical-align: 0;
  --padding-top: 25px;
  --padding-left: calc(20% + 10px);
  --padding-right: calc (10px + 20%);
  --padding-bottom: 25px;
  --placeholder-color: #aeaeae;

  // Label behaviour
  .float-label {
    .label-top {
      .dynamic-label {
        &:not(.dynamic-checkbox) {
          padding-top: var(--padding-top) !important;

          .form-mandatory-field {
            &::after {
              top: 15px;
            }
          }

          .dynamic-file-upload {
            .form-mandatory-field {
              &::after {
                top: -3px;
              }
            }
          }

          .p-filled,
          .p-inputwrapper-filled,
          .p-inputwrapper-focus,
          .p-chips-filled,
          .p-component:focus {
            ~ .dynamic-label-text {
              top: 0 !important;
              left: 0;
              text-align: var(--horizontal-align) !important;
              width: 100% !important;
            }
          }

          .dynamic-label-text {
            top: 34px !important;
            left: 8px;
            text-align: left !important;
          }

          .dynamic-slider {
            .dynamic-label-text {
              top: 0 !important;
              text-align: var(--horizontal-align);
            }
          }

          .dynamic-file-upload {
            .dynamic-label-text {
              top: 0 !important;
              left: 0;
              text-align: var(--horizontal-align);
            }
          }
        }
      }
    }

    .label-right {
      .dynamic-label {
        &:not(.dynamic-checkbox) {
          padding-right: var(--padding-right) !important;

          .p-filled,
          .p-inputwrapper-filled,
          .p-inputwrapper-focus,
          .p-chips-filled,
          .p-component:focus {
            ~ .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: 80% !important;
              text-align: var(--horizontal-align) !important;
              width: 100% !important;
              max-width: 20% !important;
              padding-left: 5px !important;
            }
          }

          .dynamic-label-text {
            top: 7px !important;
            left: 7px !important;
            text-align: left !important;
          }

          .dynamic-slider {
            .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: calc(80% + 7px) !important;
              text-align: var(--horizontal-align);
            }
          }

          .dynamic-file-upload {
            .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: 80% !important;
              text-align: var(--horizontal-align);
            }
          }
        }
      }
    }

    .label-bottom {
      .dynamic-label {
        &:not(.dynamic-checkbox) {
          padding-bottom: var(--padding-bottom) !important;

          .p-filled,
          .p-inputwrapper-filled,
          .p-inputwrapper-focus,
          .p-chips-filled,
          .p-component:focus {
            ~ .dynamic-label-text {
              top: calc(100% - 20px) !important;
              left: 0;
              text-align: var(--horizontal-align) !important;
              width: 100% !important;
            }
          }

          .dynamic-label-text {
            top: 7px !important;
            left: 8px;
            text-align: left !important;
          }

          .dynamic-slider {
            .dynamic-label-text {
              top: 45px !important;
              text-align: var(--horizontal-align);
            }
          }

          .dynamic-file-upload {
            .dynamic-label-text {
              top: calc(100% - 20px) !important;
              text-align: var(--horizontal-align);
            }
          }
        }
      }
    }

    .label-left {
      .dynamic-label {
        &:not(.dynamic-checkbox) {
          padding-left: var(--padding-left) !important;

          .p-filled,
          .p-inputwrapper-filled,
          .p-inputwrapper-focus,
          .p-chips-filled,
          .p-component:focus {
            ~ .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: 0 !important;
              text-align: var(--horizontal-align) !important;
              width: 100% !important;
              max-width: 20% !important;
              padding-left: 5px !important;
            }
          }

          .dynamic-label-text {
            top: 7px !important;
            left: calc(20% + 7px) !important;
            text-align: left !important;
          }

          .dynamic-slider {
            .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: 0 !important;
            }
          }

          .dynamic-file-upload {
            .dynamic-label-text {
              top: var(--vertical-align) !important;
              left: 0 !important;
              text-align: var(--horizontal-align);
            }
          }
        }
      }
    }
  }

  .placeholder-label {
    .dynamic-label {
      padding: 10px 0 0 !important;

      .dynamic-label-text {
        top: 18px !important;
        left: 10px !important;
        text-align: left !important;
        color: var(--placeholder-color) !important;
        font-size: 14px;
        padding-left: 0;
      }

      .p-chips,
      .p-chips-multiple {
        color: var(--placeholder-color) !important;
      }

      .p-filled,
      .p-inputwrapper-filled,
      .p-chips-filled {
        ~ .dynamic-label-text {
          display: none !important;
        }
      }

      .dynamic-slider,
      .dynamic-file-upload {
        .dynamic-label-text {
          top: -8px !important;
          min-width: 100%;
          color: #495057 !important;
        }
      }

      &.dynamic-checkbox {
        .dynamic-label-text {
          left: 30px !important;
          color: #495057 !important;
        }
      }

      .dynamic-file-upload {
        .dynamic-label-text {
          left: 0;
        }
      }

      .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
        top: 20px !important;
        left: 8px !important;
      }
    }
  }

  // Label position
  .label-top {
    .dynamic-label {
      &:not(.dynamic-checkbox) {
        padding-top: var(--padding-top);

        .dynamic-label-text {
          top: -18px;
          margin-left: 0px;

          + .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
            top: 34px;
            cursor: text;
          }
        }

        .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
          top: 10px;
          left: 8px;
          cursor: text;
        }
      }
    }
  }

  .label-right {
    .dynamic-label {
      &:not(.dynamic-checkbox) {
        padding-right: var(--padding-right);

        .dynamic-label-text {
          left: 80%;
          width: 100%;
          max-width: 20% !important;
          padding-left: 5px;
        }

        .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
          top: 8px;
          left: 8px;
        }
      }

      .p-filled,
      .p-inputwrapper-filled,
      .p-inputwrapper-focus,
      .p-chips-filled,
      .p-component:focus {
        ~ .dynamic-label-text {
          top: 8px;
        }
      }
    }
  }

  .label-bottom {
    .dynamic-label {
      &:not(.dynamic-checkbox) {
        padding-bottom: var(--padding-bottom);

        .dynamic-label-text {
          bottom: -18px;
        }

        .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
          top: 10px;
          left: 8px;
        }
      }
    }
  }

  .label-left {
    .dynamic-label {
      &:not(.dynamic-checkbox) {
        padding-left: var(--padding-left);

        .dynamic-label-text {
          left: 0;
          margin-left: 0px;
          max-width: 20% !important;
          padding-right: 5px;
        }

        .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
          top: 8px;
          left: calc(20% + 8px);
        }
      }

      .p-filled,
      .p-inputwrapper-filled,
      .p-inputwrapper-focus,
      .p-chips-filled,
      .p-component:focus {
        ~ .dynamic-label-text {
          top: 8px;
        }
      }
    }
  }

  // Text horizontal alignment
  .label-horizontal-left {
    --horizontal-align: -webkit-left;

    .dynamic-label-text {
      text-align: left !important;
    }
  }

  .label-horizontal-center {
    --horizontal-align: -webkit-center;

    .dynamic-label-text {
      text-align: center !important;
    }
  }

  .label-horizontal-right {
    --horizontal-align: -webkit-right;

    .dynamic-label-text {
      text-align: right !important;
    }
  }

  // Text vertical alignment
  .label-vertical-top {
    --vertical-align: 0;

    .dynamic-label-text {
      top: 0 !important;
    }
  }

  .label-vertical-middle {
    --vertical-align: calc(50% - 10px);

    .dynamic-label-text {
      top: calc(50% - 10px) !important;
    }
  }

  .label-vertical-bottom {
    --vertical-align: calc(100% - 20px);

    .dynamic-label-text {
      top: calc(100% - 20px) !important;
    }
  }

  .label-hidden {
    --padding-top: 0px;
    --padding-right: 0px;
    --padding-bottom: 0px;
    --padding-left: 0px;
  }
}

*:focus::placeholder,
*:not(:focus)::placeholder {
  opacity: 1 !important;
}
