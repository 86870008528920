.no-padding {
  &.p-datatable {
    .p-datatable-tbody > tr > td,
    .p-datatable-header,
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td,
    .p-datatable-tfoot > tr > td,
    .p-datatable-footer {
      padding: 0;
    }  
  }
}

.no-highlight-sortable {
  &.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: none;
    color: none;
  }
}
  
.no-header-background {
  &.p-datatable .p-datatable-header,
  &.p-datatable .p-datatable-thead > tr > th {
    background: initial;
  }
}

.no-background {
  &.p-datatable {
    .p-datatable-header,
    .p-datatable-thead > tr > th, 
    .p-datatable-scrollable-header,
    .p-datatable-scrollable-footer,
    .p-datatable-tbody > tr:not(.p-highlight) {
      background: transparent;
    }
  }

  &.p-paginator {
    background: transparent;
  }
}
